import { isMobile } from './isMobile';
import { Parser } from 'htmlparser2';

function createOverlay(show) {
  if (show) {
    if (!document.querySelector('.body-overlay')) {
      const overlay = document.createElement('div');
      overlay.className = 'body-overlay';
      overlay.style.position = 'fixed';
      overlay.style.top = '0';
      overlay.style.left = '0';
      overlay.style.right = '0';
      overlay.style.bottom = '0';
      overlay.style.backgroundColor = 'rgb(0 0 0 / 36%)';
      overlay.style.zIndex = '1000';
      document.body.appendChild(overlay);
    }
  } else {
    const overlay = document.querySelector('.body-overlay');
    if (overlay) {
      document.body.removeChild(overlay);
    }
  }
}

function scrollSmoothlyToBottom() {
  const container = document.querySelector('modal-browser');

  container.scroll({ top: container.scrollHeight });
}

class Tab {
  constructor(browserTabId, browserTabTitle, html, styles) {
    this.browserTabId = browserTabId;
    this.browserTabTitle = browserTabTitle;
    this.html = html;

    if (styles) {
      this.styles = styles;
    } else {
      const styleRegex = /<style>([\s\S]*?)<\/style>/gi;
      let match;

      while ((match = styleRegex.exec(this.html)) !== null) {
        this.styles = match[1];
      }

      this.html = this.html.replace(styleRegex, '');
    }

    console.log('Styles', this.styles);
  }


  addStyles(screen) {
    const styleElement = document.createElement('style');
    styleElement.textContent = this.styles;
    screen.appendChild(styleElement);
    console.log('add styles', screen, styleElement)
  }

  showContentWithParser(screen) {
    const fragments = [];
    for (let i = 0; i < this.html.length; i += 12) {
      fragments.push(this.html.substring(i, i + 12));
    }

    const elementStack = [];

    const parser = new Parser({
      onopentag(name, attributes) {
        const element = document.createElement(name);

        for (let attr in attributes) {
          element.setAttribute(attr, attributes[attr]);
        }

        if (elementStack.length > 0) {
          elementStack[elementStack.length - 1].appendChild(element);
        } else {
          screen.appendChild(element);
        }

        elementStack.push(element);
        scrollSmoothlyToBottom();  
      },
      ontext(text) {
        if (elementStack.length > 0) {
          elementStack[elementStack.length - 1].appendChild(document.createTextNode(text));
        }
        scrollSmoothlyToBottom();  // Smooth scroll to bottom whenever text is added
      },
      onclosetag(tagname) {
        elementStack.pop();
      },
    });

    let i = 0;

    function parseNextFragment() {
      if (i < fragments.length) {
        parser.write(fragments[i]);
        i++;
        setTimeout(parseNextFragment, 9);
      } else {
        this.running = false;
        parser.end();
      }
    }

    this.running = true;
    parseNextFragment();
  }
}

class Location {
  constructor(tabs = []) {
    this.tabs = tabs;
  }

  addTab(tab) {
    this.tabs.push(tab);
  }

  getTabIndex(browserTabId) {
    return this.tabs.findIndex(tab => tab.browserTabId === browserTabId);
  }

  getTabByTitle(title) {
    return this.tabs.findIndex(tab => tab.browserTabTitle === title);
  }
}

class BackgroundImageDiv extends HTMLElement {
  static get observedAttributes() {
    return ['image', 'message'];
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.loadImage();
    }
  }

  loadImage() {
    const imageUrl = this.getAttribute('image');
    const img = new Image();
    img.onload = () => {
      this.setBackgroundImage(imageUrl);
    };
    img.onerror = () => {
      console.error('Failed to load image:', imageUrl);
    };
    img.src = imageUrl;
  }

  setBackgroundImage(imageUrl) {
    this.render(imageUrl);

    setTimeout(() => {
      this.shadowRoot.querySelector('.background').classList.add('visible');
      this.shadowRoot.querySelector('.background').addEventListener('click', this.handleClick.bind(this));
    }, 100);
  }

  render(imageUrl) {
    this.shadowRoot.innerHTML = `
          <style>
            .background {
              width: 100%;
              height: 110px;
              background-size: cover;
              background-position: center;
              background-image: url('${imageUrl}');
              opacity: 0;
              transition: opacity 2s;
              cursor: pointer;
            }

            .background.visible {
              opacity: 1;
            }
          </style>
          <div class="background shadow-md rounded-lg cursor-pointer"></div>
        `;
  }

  handleClick(event) {
    const message = this.getAttribute('message');
    if (message) {
      window.sendMessage(event, message);
    }
  }
}

class ModalBrowser extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.history = this.loadHistoryFromLocalStorage();
    this.currentHistoryIndex = this.history.length - 1;
    this.currentTabIndex = 0;
    this.running = false;
    if (isMobile()) this.classList.add('mobile');
    window.addEventListener('resize', this.adjustWidth.bind(this));
  }

  get shouldBeDisplayed() {
    return !isMobile() && this.hasAttribute('displayed');
  }

  connectedCallback() {
    this.style.position = this.shouldBeDisplayed ? 'absolute' : 'fixed';

    if (this.shouldBeDisplayed) {
      this.style.top = this.getAttribute('top') ? this.getAttribute('top') : null;
      this.style.left = this.getAttribute('left') ? this.getAttribute('left') : null;
    } else {
      if (isMobile()) {
        this.style.top = 0;
        this.style.left = 0;
      } else {
        this.style.bottom = this.getAttribute('bottom') ? this.getAttribute('bottom') : !this.getAttribute('top') ? '120px' : null;
        this.style.right = !this.getAttribute('left') ? this.getAttribute('right') : null;
      }
    }

    if (this.hasAttribute('width')) this.style.width = this.getAttribute('width');
    if (this.hasAttribute('height')) this.style.height = this.getAttribute('height');

    this.style.display = 'block';
    this.style.opacity = '0';
    this.style.visibility = 'hidden';

    if (!this.shouldBeDisplayed) {
      this.style.transition = 'opacity 0.5s, width 0.5s ease-out';
    }

    this.style.overflow = 'auto';

    this.adjustWidth();
    this.render();

    if (this.shouldBeDisplayed) {
      setTimeout(() => this.show(), 1000);
    }

    this.shadowRoot.querySelector('.close').addEventListener('click', (e) => { e.preventDefault(); this.hide(); });
    this.shadowRoot.querySelector('.back').addEventListener('click', (e) => { e.preventDefault(); this.navigateBack(); });
    this.shadowRoot.querySelector('.forward').addEventListener('click', (e) => { e.preventDefault(); this.navigateForward(); });
  }

  adjustWidth() {
    if (this.hasAttribute('width')) {
      this.style.width = this.getAttribute('width');
      return;
    }

    const chatContainerWidth = 360;
    const spaceBetween = 20;
    const rightMargin = this.shouldBeDisplayed ? 50 : 20;
    let futureBrowserWidth = window.innerWidth - chatContainerWidth - spaceBetween - rightMargin - 100;
    if (window.innerWidth < 950) futureBrowserWidth += 50;
    this.desiredWidth = `${futureBrowserWidth}px`;
    this.style.width = this.isVisible ? this.desiredWidth : '0px';
    this.style.right = `${chatContainerWidth + spaceBetween + 15}px`;
  }

  adjustHeight() {
    const element = document.querySelector('asisgenio-chat') || document.querySelector('support-button').shadowRoot.querySelector('asisgenio-chat');

    const height = this.getAttribute('height') 
      ? this.getAttribute('height')
      : `${element.offsetHeight - 2}px`

    this.style.height = this.shouldBeDisplayed ? '90%' : height;
  }

  render() {
    this.shadowRoot.innerHTML = `
      <style>
        @import "https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css";
        :host {
          border-radius: 15px;
          overflow: hidden;
          padding: 15px;
          z-index: 9999999999;
          scrollbar-width: thin;
          scrollbar-color: #9a9ac1 #1a202c;
          background-color: ${ this.shouldBeDisplayed ? 'rgb(31 41 55 / 0.2)' : '#191526'};
          color: #ffffff;
          position: relative;
        }
        :host::-webkit-scrollbar { width: 12px; }
        :host::-webkit-scrollbar-track { background: #1a202c; }
        :host::-webkit-scrollbar-thumb {
          background-color: #9a9ac1;
          border-radius: 10px;
          border: 3px solid #1a202c;
        }
        :host(.mobile) {
          display: block;
          position: fixed;
          width: 100% !important;
          height: 100% !important;
          max-height: 100vh;
          top: 0;
          left: 0;
          border-radius: 0;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.5s ease, visibility 0.5s;
          box-shadow: none;
        }
        [data-visible-on-match] {
          display: none !important;
        }
        [data-visible-on-match].visible {
          display: block !important;
        }
        @media (max-width: 700px) {
          ${!isMobile() ? `
            :host {
              position: absolute !important;
              top: 49%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 96% !important;
              right: 10px !important;
              max-height: 53vh;
              border-radius: 0;
              box-shadow: none;
            }
          ` : ''}
        }
        #modal-browser {
          position: relative;
          height: 100%;
        }
        .buttons {
          position: absolute;
          top: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        .buttons .close,
        .buttons .back,
        .buttons .forward {
          position: absolute;
          color: white;
          text-decoration: none;
        }
        .buttons .close { right: 0; }
        .buttons .back { left: 0; }
        .buttons .forward { left: 35px; }
        .tabs {
          position: absolute;
          top: 0;
          left: 70px;
          right: 70px;
          display: flex;
          justify-content: center;
          overflow-x: auto;
          scrollbar-width: none;
          -ms-overflow-style: none;
          font-size: 12px;
        }
        ${ this.shouldBeDisplayed ? '.buttons .close { display: none; }' : '' }
        .tabs::-webkit-scrollbar { display: none; }
        .tab {
          margin: 0 5px;
          padding: 5px 10px;
          background: #2d3748;
          border-radius: 10px;
          cursor: pointer;
          color: white;
        }
        .tab.selected { background: #4a5568; }
        .content-wrapper { padding-top: 40px; height: 100%; }
        :host(.mobile) .buttons .close { right: 0; }
        .disabled { pointer-events: none; }
        .buttons svg { stroke-width: 2px; }
        .disabled svg { stroke: gray !important; }
        .loading-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.4);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.3s ease, visibility 0.3s ease;
          z-index: 9999999999;
        }
        .loading-overlay.visible { opacity: 1; visibility: visible; }
        .spinner {
          border: 4px solid rgba(255, 255, 255, 0.3);
          border-top: 4px solid white;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }

      .tab-content {
        display: none; /* Hide all content divs by default */
      }
      .tab-content.visible {
        height: 100%;
        display: block; /* Only show the active content div */
      }
      </style>
      <div id='modal-browser'>
        <div class="buttons">
          <a href="#" class="close">
            <svg viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="1.5" style="width: 26px; height: 26px; display: block;">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="6" y1="12" x2="18" y2="12" strokeWidth="1.5"></line>
            </svg>
          </a>
          <a href="#" class="back ${this.currentHistoryIndex <= 0 ? 'disabled' : ''}">
            <svg viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="1.5" style="width: 26px; height: 26px; display: block;">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="16" y1="12" x2="8" y2="12" strokeWidth="1.5"></line>
              <line x1="10" y1="10" x2="8" y2="12" strokeWidth="1.5"></line>
              <line x1="10" y1="14" x2="8" y2="12" strokeWidth="1.5"></line>
            </svg>
          </a>
          <a href="#" class="forward ${this.currentHistoryIndex >= this.history.length - 1 ? 'disabled' : ''}">
            <svg viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth="1.5" style="width: 26px; height: 26px; display: block;">
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="8" y1="12" x2="16" y2="12" strokeWidth="1.5"></line>
              <line x1="14" y1="10" x2="16" y2="12" strokeWidth="1.5"></line>
              <line x1="14" y1="14" x2="16" y2="12" strokeWidth="1.5"></line>
            </svg>
          </a>
        </div>
        <div class="tabs"></div>
        <div class="content-wrapper">
          <div class="content mt-2">${this.history.length ? this.renderTabs(true) : this.getDefaultTemplate()}</div>
        </div>
        <div class="loading-overlay"></div>
      </div>
    `;
  }

  show({ browserTabId, browserTabTitle, html, action, href } = {}) {
    console.log('Shooooooow')
    console.log({ browserTabId, browserTabTitle })

    const now = Date.now();
    const withinFiveSeconds = this.lastShowTime && (now - this.lastShowTime) < 5000;  // Check the time difference

    this.style.opacity = '1';
    this.style.visibility = 'visible';
    this.style.width = this.desiredWidth;
    this.isVisible = true;

    if (!this.shouldBeDisplayed)
      createOverlay(true);

    this.adjustHeight();

    if (action === 'openLink' && href) {
      browserTabId = `tab-${Date.now()}`;
      browserTabTitle = `External Link`;
      html = `<iframe src="${href}" style="width: 100%; height: 69vh; border: none;"></iframe>`;
    }

    if (!browserTabId && !browserTabTitle && !html) {
      // Show the last visited tab or default template
      if (this.history.length > 0) {
        const lastLocation = this.history[this.history.length - 1];
        this.currentHistoryIndex = this.history.length - 1;
        this.currentTabIndex = lastLocation.tabs.length - 1;
      } else {
        this.shadowRoot.querySelector('.content').innerHTML = this.getDefaultTemplate();
        this.hideLoading();
        return;
      }
    } else {
      const newTab = new Tab(browserTabId, browserTabTitle, html);

      if (withinFiveSeconds) {
        const currentLocation = this.history[this.currentHistoryIndex];
        currentLocation.addTab(newTab);
        this.currentTabIndex = currentLocation.tabs.length - 1;
      } else {
        const newLocation = new Location([newTab]);
        this.history.push(newLocation);
        this.currentHistoryIndex = this.history.length - 1;
        this.currentTabIndex = 0;
      }

      this.lastShowTime = now; // Update the last show time

      console.log(this.test, new Date)
      this.test = new Date()
    }

    this.saveHistoryToLocalStorage();
    this.updateNavigationButtons();
    this.renderTabs(true);
    this.hideLoading();
    this.scrollTop = 0;
  }

  getDefaultTemplate() {
    return `
        <div class="flex items-center justify-center h-full" style="${this.shouldBeDisplayed ? 'margin-top: 22%;' : ''} height: ${isMobile() ? '100%' : `${parseInt(this.style.height) - 100}px;`}">
          <div class="text-center">
            <div class="flex justify-center space-x-4 mb-4">
              <img src="https://pinecall.io/avatars/31.png" alt="Avatar de Fabi" class="w-14 h-14 rounded-full border-4 border-gray-700">
              <img src="https://pinecall.io/avatars/32.png" alt="Avatar de Alex" class="w-14 h-14 rounded-full border-4 border-gray-700">
              <img src="https://pinecall.io/avatars/33.png" alt="Avatar de Mia" class="w-14 h-14 rounded-full border-4 border-gray-700">
            </div>
            <h2 class="text-2xl font-semibold">Hello, we are the Pinecall team,</h2>

            <p class="mt-2">Your AI-powered phone agent experts.</p>
            <p class="mt-2">Interact with us to experience smart, seamless assistance. </p>
            <p class="mt-2">All interaction details will be displayed here for an enhanced customer experience.</p>
          </div>
        </div>
      `;
  }

  check(text) {
  }

  hide() {
    this.style.opacity = '0';
    this.style.width = '0px';
    setTimeout(() => {
      this.style.visibility = 'hidden'
      createOverlay(false);
    }, 500);
    this.isVisible = false;
    this.shadowRoot.querySelector('.content').innerHTML = '';
  }

  areAllElementsHidden() {
    const elements = this.shadowRoot.querySelector('.content').children;
    for (let i = 0; i < elements.length; i++) {
      const el = elements[i];
      const isVisible = el.classList.contains('visible') || (getComputedStyle(el).visibility !== 'hidden' && getComputedStyle(el).opacity !== '0');
      if (isVisible) return false;
    }
    return true;
  }

  showLoading() {
    return;
    const loadingOverlay = this.shadowRoot.querySelector('.loading-overlay');
    if (loadingOverlay) loadingOverlay.classList.add('visible');
    this.isContentLoading = true;
  }

  hideLoading() {
    const loadingOverlay = this.shadowRoot.querySelector('.loading-overlay');
    if (loadingOverlay) loadingOverlay.classList.remove('visible');
    this.isContentLoading = false;
  }

  navigateBack() {
    if (this.currentHistoryIndex > 0) {
      this.currentHistoryIndex--;
      this.currentTabIndex = 0;
      this.saveHistoryToLocalStorage();
      this.updateNavigationButtons();
      this.renderTabs();
      this.removeLoading();
    }
  }

  removeLoading() {
    this.shadowRoot.querySelectorAll('[data-visible-on-match]').forEach(el => el.classList.add('visible'));
    this.shadowRoot.querySelector('.loading')?.remove();
  }

  navigateForward() {
    if (this.currentHistoryIndex < this.history.length - 1) {
      this.currentHistoryIndex++;
      this.currentTabIndex = 0;
      this.saveHistoryToLocalStorage();
      this.updateNavigationButtons();
      this.renderTabs();
      this.removeLoading();
    }
  }

  updateNavigationButtons() {
    const backButton = this.shadowRoot.querySelector('.back');
    const forwardButton = this.shadowRoot.querySelector('.forward');
    backButton.classList.toggle('disabled', this.currentHistoryIndex <= 0);
    forwardButton.classList.toggle('disabled', this.currentHistoryIndex >= this.history.length - 1);
  }

  renderTabs(streaming = false) {
    const currentLocation = this.history[this.currentHistoryIndex];
    const tabsContainer = this.shadowRoot.querySelector('.tabs');
    const contentWrapper = this.shadowRoot.querySelector('.content-wrapper');
    if (tabsContainer && currentLocation) {
      tabsContainer.innerHTML = '';
      contentWrapper.innerHTML = ''; // Limpiar contenido actual
      currentLocation.tabs.forEach((tab, index) => {
        // Creando elemento de tab
        const tabElement = document.createElement('div');
        tabElement.className = `tab ${index === this.currentTabIndex ? 'selected' : ''} i-${index}`;
        tabElement.innerText = tab.browserTabTitle;
        tabElement.addEventListener('click', () => this.switchTab(index));
        tabsContainer.appendChild(tabElement);

        // Creando div de contenido para cada tab
        const contentDiv = document.createElement('div');
        contentDiv.className = `tab-content ${index === this.currentTabIndex ? 'visible' : 'invisible'} i-${index}`;
        contentWrapper.appendChild(contentDiv);
      });

      // Construir el contenido de todos los tabs en paralelo
      this.showAllTabsContent(streaming);
    }
  }

  switchTab(index) {
    this.currentTabIndex = index;

    const tabsContainer = this.shadowRoot.querySelector('.tabs');
    const tabElements = tabsContainer.querySelectorAll(`.tab`);

    tabElements.forEach((tabElement) => {
      if (tabElement.classList.contains(`i-${index}`)) {
        tabElement.classList.add('selected');
      } else {
        tabElement.classList.remove('selected');
      }
    });

    const contentWrapper = this.shadowRoot.querySelector('.content-wrapper');
    const contentDivs = contentWrapper.querySelectorAll(`.tab-content`);

    contentDivs.forEach((contentDiv) => {
      if (contentDiv.classList.contains(`i-${index}`)) {
        contentDiv.classList.add('visible');
        contentDiv.classList.remove('invisible');
      } else {
        contentDiv.classList.remove('visible');
        contentDiv.classList.add('invisible');
      }
    });

    this.removeLoading(); // Si tienes este método, asumo que quita algún indicador de carga.
  }

  showAllTabsContent(streaming) {
    const currentLocation = this.history[this.currentHistoryIndex];
    if (currentLocation) {
      currentLocation.tabs.forEach((tab, index) => {
        this.showCurrentTabContent(index, streaming);
      });
    }
  }

  showCurrentTabContent(tabIndex, streaming = false) {
    const currentLocation = this.history[this.currentHistoryIndex];
    if (currentLocation && currentLocation.tabs[tabIndex]) {
      const currentTab = currentLocation.tabs[tabIndex];
      const contentWrapper = this.shadowRoot.querySelector('.content-wrapper');
      const contentDiv = contentWrapper.querySelector(`.tab-content.i-${tabIndex}`);
      if (!contentDiv.innerHTML || streaming) {
        if (streaming) {
          currentTab.addStyles(contentDiv);
          currentTab.showContentWithParser(contentDiv);
        } else {
          setTimeout(() => currentTab.addStyles(contentDiv));
          contentDiv.innerHTML = currentTab.html;
        }
      }
    }
  }

  loadHistoryFromLocalStorage() {
    try {
      const rawHistory = JSON.parse(localStorage.getItem('modalBrowserHistory') || '[]');
      return rawHistory.map(location => new Location(location.tabs.map(tab => new Tab(tab.browserTabId, tab.browserTabTitle, tab.html, tab.styles))));
    } catch (error) {
      console.error('Failed to load modal history from localStorage', error);
      return [];
    }
  }

  saveHistoryToLocalStorage() {
    try {
      localStorage.setItem('modalBrowserHistory', JSON.stringify(this.history));
    } catch (error) {
      console.error('Failed to save modal history to localStorage', error);
    }
  }

  clear() {
    try {
      const backButton = this.shadowRoot.querySelector('.back');
      const forwardButton = this.shadowRoot.querySelector('.forward');
      localStorage.removeItem('modalBrowserHistory');

      this.history = [];
      this.currentHistoryIndex = -1;
      this.currentTabIndex = -1;

      this.shadowRoot.querySelector('.content-wrapper').innerHTML = this.getDefaultTemplate();
      this.shadowRoot.querySelector('.tabs').innerHTML = '';

      backButton.classList.toggle('disabled', true);
      forwardButton.classList.toggle('disabled', true);

      console.log('Modal history cleared successfully.');
    } catch (error) {
      console.error('Failed to clear modal history from localStorage', error);
    }
  }
}

customElements.define('background-image-div', BackgroundImageDiv);
customElements.define('modal-browser', ModalBrowser);
