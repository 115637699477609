class GoogleMap extends HTMLElement {
  connectedCallback() {
    const lat = parseFloat(this.getAttribute('lat'));
    const long = parseFloat(this.getAttribute('long'));
    const width = this.getAttribute('width') || '100%';
    const height = this.getAttribute('height') || '400px';

    // Apply width and height styles
    this.style.width = width;
    this.style.height = height;
    this.style.display = 'block'; 

    if (!window.google) {
      window.initMap = () => {
        this.initMap(lat, long);
      };
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCHtl8uM0Ye5nSU1-Z4QEubXrUWaUls5O4&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    } else {
      this.initMap(lat, long);
    }
  }

  initMap(lat, long) {
    const map = new google.maps.Map(this, {
      center: { lat, lng: long },
      zoom: 14
    });

    new google.maps.Marker({
      position: { lat, lng: long },
      map: map
    });
  }
}

customElements.define('google-map', GoogleMap);
