import { isMobile } from './isMobile';

class SupportButton extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.chatVisible = false; // To track the state of the chat window.
  }

  connectedCallback() {
    this.render();
    this.shadowRoot.querySelector('button').addEventListener('click', () => this.toggleChat());
    this.setTheme(this.theme);
  }

  render() {
    const agent = this.getAttribute('agent') || '';
    const mobileClass = isMobile() ? 'mobile' : '';

    this.hide = this.getAttribute('hide') === 'true' || false;
    this.theme = this.getAttribute('theme') || 'light';
    this.bottom = this.hide ? '20px' : '120px';
    this.btnBgColor = this.getAttribute('btn-bg-color') || '#6a6ab4';
    this.btnDarkBgColor = this.getAttribute('btn-dark-bg-color') || '#444485';
    this.btnTextColor = this.getAttribute('btn-text-color') || 'white';
    this.darkTextColor = this.getAttribute('btn-dark-text-color') || 'white';

    this.bgColor = this.getAttribute('bg-color');
    this.darkBgColor = this.getAttribute('dark-bg-color');
    this.assistantBgColor = this.getAttribute('assistant-bg-color');
    this.userBgColor = this.getAttribute('user-bg-color');
    this.darkAssistantBgColor = this.getAttribute('dark-assistant-bg-color');
    this.darkUserBgColor = this.getAttribute('dark-user-bg-color');

    this.darkTextfieldBgColor =  this.getAttribute('dark-textfield-bg-color');
    this.textfieldBgColor =  this.getAttribute('textfield-bg-color');
    this.darkTextfieldColor =  this.getAttribute('dark-textfield-color');
    this.textfieldColor =  this.getAttribute('textfield-color');

    this.url = this.getAttribute('url') || 'https://asisgenio.com';

    let chatAttributes = `agent="${agent}" theme="${this.theme}" width="100%" height="100%" close="true"`;

    chatAttributes += this.url ? ` url="${this.url}"` : '';
    chatAttributes += this.bgColor ? ` bg-color="${this.bgColor}"` : '';
    chatAttributes += this.darkBgColor ? ` dark-bg-color="${this.darkBgColor}"` : '';
    chatAttributes += this.assistantBgColor ? ` assistant-bg-color="${this.assistantBgColor}"` : '';
    chatAttributes += this.userBgColor ? ` user-bg-color="${this.userBgColor}"` : '';
    chatAttributes += this.darkAssistantBgColor ? ` dark-assistant-bg-color="${this.darkAssistantBgColor}"` : '';
    chatAttributes += this.darkUserBgColor ? ` dark-user-bg-color="${this.darkUserBgColor}"` : '';

    chatAttributes += this.darkTextfieldBgColor ? ` dark-textfield-bg-color="${this.darkTextfieldBgColor}"` : '';
    chatAttributes += this.textfieldBgColor ? ` textfield-bg-color="${this.textfieldBgColor}"` : '';
    chatAttributes += this.darkTextfieldColor ? ` dark-textfield-color="${this.darkTextfieldColor}"` : '';
    chatAttributes += this.textfieldColor ? ` textfield-color="${this.textfieldColor}"` : '';

    this.shadowRoot.innerHTML = `
      <style>
        button {
          position: fixed;
          text-align: center;
          bottom: 20px;
          right: 20px;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          border: none;
          cursor: pointer;
          box-shadow: 0 2px 5px rgba(0,0,0,0.3);
          padding: 0;
          font-size: 18px;
          z-index: 99999999;
        }

        .chat-container {
          display: none;
          position: fixed;
          right: 20px;
          bottom: ${this.bottom};
          width: 360px;
          height: 0; 
          overflow: hidden;
          transition: height 0.5s ease;
          box-shadow: 0 2px 10px rgba(0,0,0,0.3);
          border-radius: 15px;
          max-height: 79vh;
          z-index: 99999999;
        }

       .chat-container.mobile {
          display: block; /* Always block but hidden by other means */
          position: fixed;
          width: 100%;
          height: 100% !important;
          top: 0;
          left: 0;
          border-radius: 0;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.5s ease, visibility 0.5s; /* Include visibility in transition */
          max-height: 100vh;
        }

        .chat-container.active {
          opacity: 1;
          visibility: visible;
          pointer-events: all; /* Allow interactions */
        }

        @media (max-width: 700px) {
          .chat-container:not(.mobile) {
            position: absolute;
            width: 96%;
            height: 98% !important;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        asisgenio-chat {
          display: block; /* Make sure it fills the container */
          width: 100%;
          height: 100%;
        }
      </style>

      <button class="support-btn" style="${this.hide ? 'display: none' : ''}">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16">
            <path d="M8 2C4.686 2 2 4.686 2 8c0 1.626.707 3.08 1.833 4.118C3.155 13.69 1.937 14.962 1 16c1.037-.937 2.31-2.155 3.882-2.833A5.96 5.96 0 0 0 8 14c3.314 0 6-2.686 6-6s-2.686-6-6-6zm0 1c2.761 0 5 2.239 5 5s-2.239 5-5 5a4.979 4.979 0 0 1-2.5-.656l-.497-.248-.746.373c-.693.346-1.422.624-2.09.82a15.97 15.97 0 0 0 1.856-2.725l.261-.497-.397-.318A4.979 4.979 0 0 1 3 8c0-2.761 2.239-5 5-5z"/>
        </svg>
        <br />
        Chat
      </button>

      <div class="chat-container ${mobileClass}">
        <asisgenio-chat ${chatAttributes}></asisgenio-chat>
      </div>
    `;
  }

  sendMessage(content) {
    this.shadowRoot.querySelector(`asisgenio-chat`).sendMessage(content);
  }

  setTheme(theme) {
    this.theme = theme;
    const btn = this.shadowRoot.querySelector('.support-btn');

    if (this.theme === 'light') {
      btn.style.backgroundColor = this.btnBgColor;
      btn.style.color = this.btnTextColor;
    } else {
      btn.style.backgroundColor = this.btnDarkBgColor;
      btn.style.color = this.darkTextColor;
    }
  }

  toggleChat(type = null) {
    const chatContainer = this.shadowRoot.querySelector('.chat-container');

    if (isMobile()) {
      if (this.chatVisible || type == 'close') {
        chatContainer.classList.remove('active');
        document.body.classList.remove('no-scroll');
      } else {
        chatContainer.classList.add('active');
        document.body.classList.add('no-scroll');
      }
    } else {
      if (this.chatVisible || type == 'close') {
        chatContainer.style.height = '0';
        setTimeout(() => { chatContainer.style.display = 'none'; }, 500); // Wait for animation
      } else {
        chatContainer.style.display = 'block';
        setTimeout(() => { chatContainer.style.height = '660px'; }, 0); // Start animation
      }
    }

    this.chatVisible = type === 'close' ? false : !this.chatVisible;
  }

  loadAgent(agent) {
    this.setAttribute('agent', agent); 
    this.render();
    this.shadowRoot.querySelector('button').addEventListener('click', () => this.toggleChat());
    this.setTheme(this.theme);

    setTimeout(() => {
      this.toggleChat();
    }, 1000);
  }
}

customElements.define('support-button', SupportButton);
