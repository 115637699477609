class GalleryModal extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.currentIndex = 0;
    this.images = [];
    this.imageElements = [];
  }

  connectedCallback() {
    this.render();
    this.shadowRoot.querySelector('.close').addEventListener('click', this.close.bind(this));
    this.shadowRoot.querySelector('.prev').addEventListener('click', this.prev.bind(this));
    this.shadowRoot.querySelector('.next').addEventListener('click', this.next.bind(this));

    this.loadGalleryItems();

    document.addEventListener('keydown', this.handleKeyDown.bind(this));

    // Touch events for mobile
    this.touchStartX = 0;
    this.touchEndX = 0;
    this.shadowRoot.querySelector('.modal-content').addEventListener('touchstart', this.touchStart.bind(this), false);
    this.shadowRoot.querySelector('.modal-content').addEventListener('touchend', this.touchEnd.bind(this), false);

    // Observe additions to the gallery item list
    const observer = new MutationObserver(this.loadGalleryItems.bind(this));
    const config = { childList: true, subtree: true };
    observer.observe(this, config);
  }

  disconnectedCallback() {
    document.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }

  handleKeyDown(event) {
    if (this.shadowRoot.querySelector('.modal').classList.contains('hidden')) return;

    switch (event.key) {
      case 'Escape':
        this.close();
        break;
      case 'ArrowLeft':
        this.prev();
        break;
      case 'ArrowRight':
        this.next();
        break;
    }
  }

  loadGalleryItems() {
    this.querySelectorAll('gallery-item').forEach((item, index) => {
      const url = item.getAttribute('data-url');
      if (!this.images.includes(url)) {
        this.images.push(url);
        item.addEventListener('click', () => this.open(index));

        // Create image elements to prefetch
        const img = new Image();
        img.src = url;
        this.imageElements.push(img);
      }
    });
  }

  touchStart(event) {
    this.touchStartX = event.changedTouches[0].screenX;
  }

  touchEnd(event) {
    this.touchEndX = event.changedTouches[0].screenX;
    this.handleGesture();
  }

  handleGesture() {
    if (this.touchEndX < this.touchStartX) {
      this.next();
    }

    if (this.touchEndX > this.touchStartX) {
      this.prev();
    }
  }

  open(index) {
    this.currentIndex = index;
    this.updateImage(true); // True indicates high-res image on open
    this.shadowRoot.querySelector('.modal').classList.remove('hidden');
    document.body.style.overflow = 'hidden';  // Prevent scrolling when modal is open
  }

  close() {
    const modalImage = this.shadowRoot.querySelector('.modal-image');
    this.shadowRoot.querySelector('.modal').classList.add('hidden');
    document.body.style.overflow = '';  // Restore scrolling
    setTimeout(() => {
      modalImage.src = '';  // Clear the image source to prevent the flickering of the previous image
      modalImage.classList.remove('loaded');
    }, 500); // This should give enough time to complete the transition before resetting the src
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
    this.updateImage();
  }

  prev() {
    this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    this.updateImage();
  }

  updateImage(isInitial = false) {
    const modalImage = this.shadowRoot.querySelector('.modal-image');
    const loader = this.shadowRoot.querySelector('.loader');
    modalImage.classList.remove('loaded'); // Remove the 'loaded' class to restart animation
    loader.style.display = 'block';

    const newImage = new Image();
    const url = this.images[this.currentIndex];
    newImage.onload = () => {
      modalImage.src = newImage.src;
      setTimeout(() => {
        modalImage.classList.add('loaded'); // Add 'loaded' class to trigger transition
        loader.style.display = 'none';
      }, 10); // Slight delay to ensure the class is added after the image src is set
    };

    if (isInitial) {
      // Load high resolution images for the initial display
      newImage.src = url;
    } else {
      // Only load high resolution images as needed for following images
      setTimeout(() => { newImage.src = url; }, 10);
    }
  }

  render() {
    this.shadowRoot.innerHTML = `
      <style>
        :host {
          display: block;
          width: 100%;
        }
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.9);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }
        .hidden {
          display: none;
        }
        .modal-content {
          position: relative;
          max-width: 90%;
          max-height: 90%;
        }
        .modal-image {
          width: 100%;
          height: auto;
          transform: scale(0.7); /* Initial state for transition */
          transition: transform 0.5s;
          margin-top: -50px;
        }
        .loaded {
          transform: scale(0.9); /* End state for transition */
        }
        .loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;
          border: 4px solid #f3f3f3;
          border-radius: 50%;
          border-top: 4px solid #444;
          width: 40px;
          height: 40px;
          animation: spin 1s linear infinite;
        }
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        .close, .prev, .next {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(0, 0, 0, 0.5);
          color: white;
          border: none;
          padding: 20px;
          cursor: pointer;
          font-size: 24px;
        }
        .close { right: 10px; top: 20px; transform: none; }
        .prev { left: 10px; }
        .next { right: 10px; }
        .gallery-wrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
          width: 100%;
        }
      </style>
      <div class="modal hidden">
        <div class="modal-content">
          <div class="loader"></div>
          <img class="modal-image" src="">
          <button class="close">&times;</button>
          <button class="prev">&#10094;</button>
          <button class="next">&#10095;</button>
        </div>
      </div>
      <div class="gallery-wrapper">
        <slot></slot>
      </div>
    `;
  }
}

customElements.define('gallery-modal', GalleryModal);
