import './chat.js'
import './modal-browser.js'
import './support-button.js'
import './google-map.js'
import './gallery-modal.js'
import './gallery-item.js';

function sendMessage(event, content) {
  event.preventDefault();

  const linkElement = event.target;

  console.log('link element', event.target)

  // Deshabilita el enlace y cambia el contenido a un spinner
  linkElement.classList.add('opacity-50', 'cursor-not-allowed', 'pointer-events-none');
  const originalContent = linkElement.innerHTML;
  linkElement.innerHTML = 'Loading...';

  const element = document.querySelector('support-button') || document.querySelector('matched-chat');

  setTimeout(() => {
    element.sendMessage(content);

    linkElement.innerHTML = originalContent;
    linkElement.removeAttribute('disabled');
  }, 2000); // Reemplaza esto con la lógica real de envío de mensajes
}

window.sendMessage = sendMessage;

