import { isMobile } from './isMobile';

class ChatComponent extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.iframMessageListener = this.handleMessageEvent.bind(this);
  }

  connectedCallback() {
    const width = this.getAttribute('width') || '100%';
    const heightAttr = this.getAttribute('height') || '100%';
    const left = this.getAttribute('left') || null;
    const top = this.getAttribute('top') || null;
    const agent = this.getAttribute('agent') || '';
    const theme = this.getAttribute('theme') || 'dark';
    const close = this.getAttribute('close') || 'false';
    const bgColor = this.getAttribute('bg-color');
    const darkBgColor = this.getAttribute('dark-bg-color');
    const userBgColor = this.getAttribute('user-bg-color');
    const assistantBgColor = this.getAttribute('assistant-bg-color');
    const darkUserBgColor = this.getAttribute('dark-user-bg-color');
    const darkAssistantBgColor = this.getAttribute('dark-assistant-bg-color');

    const darkTextfieldBgColor =  this.getAttribute('dark-textfield-bg-color');
    const textfieldBgColor =  this.getAttribute('textfield-bg-color');
    const darkTextfieldColor =  this.getAttribute('dark-textfield-color');
    const textfieldColor =  this.getAttribute('textfield-color');

    const height = this.calculateHeight(heightAttr);

    const url = this.getAttribute('url') || 'https://asisgenio.com';

    this.render({
      url,
      width,
      left,
      top,
      height,
      agent,
      theme,
      close,
      bgColor,
      darkBgColor,
      userBgColor,
      assistantBgColor,
      darkAssistantBgColor,
      darkUserBgColor,
      darkTextfieldBgColor,
      textfieldBgColor,
      darkTextfieldColor,
      textfieldColor
    });

    window.addEventListener('message', this.iframMessageListener, false);
  }

  disconnectedCallback() {
    window.removeEventListener('message', this.iframMessageListener, false);
  }

  sendMessageToIframe(message) {
    const iframe = this.shadowRoot.querySelector('.iframe-app'); // Coloca el selector correcto de tu iframe
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(message, '*'); // Elige un origen seguro en lugar de '*'
    }
  }

  sendMessage(content) {
    document.querySelector('modal-browser')?.showLoading();
    this.sendMessageToIframe({ type: 'sendMessage', content });
  }

  handleMessageEvent(event) {
    const msg = event.data;

    if (event.data instanceof Blob) 
      return this.playVoiceMessage(msg)

    if (typeof(msg) !== 'object' || !msg.type)
      return;

    if (msg.type === 'close') {
      const agent = this.getAttribute('agent') || '';
      document.querySelector('modal-browser')?.hide();
      document.querySelectorAll(`support-button[agent="${agent}"]`).forEach(el => el.toggleChat('close'));
      return true;
    }

    if (msg.type === 'makeCall')
      window.location.href = `tel:${msg.phoneNumber}`

    if (msg.type === 'theme')
      return document.querySelector('support-button').setTheme(msg.theme);

    if (msg.type === 'clear') 
        return document.querySelector('modal-browser').clear();

    if (msg.type === 'modal') {
      console.log("====")
      console.log(msg);
      if (['show', 'openLink'].includes(msg.action)) {
        return document.querySelector('modal-browser').show(msg);
      } else {
        return document.querySelector('modal-browser').hide();
      }
    }

    if (msg.type === 'chatFunctionSentence') {
      return document.querySelector('modal-browser').check(msg.sentence);
    }
  }

  playVoiceMessage(blob) {
    var audioURL = URL.createObjectURL(event.data);
    var audio = new Audio(audioURL);
    audio.play().catch(error => console.log(error));
  }

  calculateHeight(height) {
    if (height.endsWith('%')) {
      const percentage = parseFloat(height) / 100;
      const viewportHeight = window.innerHeight;
      return `${percentage * viewportHeight}px`;
    }

    return height;
  }

  render(options) {
    const { width, left, top, height, agent, theme, close, bgColor, darkBgColor, userBgColor, assistantBgColor, darkAssistantBgColor, darkUserBgColor, darkTextfieldBgColor, textfieldBgColor, darkTextfieldColor, textfieldColor, url } = options;

    const showBox = window.innerHeight > 620;

    const newUrl = `${url}/chat/${agent}?widget=true&theme=${theme}&showBox=${showBox}&close=${close}&bgColor=${encodeURIComponent(bgColor || '')}&darkBgColor=${encodeURIComponent(darkBgColor || '')}&userBgColor=${encodeURIComponent(userBgColor || '')}&assistantBgColor=${encodeURIComponent(assistantBgColor || '')}&darkAssistantBgColor=${encodeURIComponent(darkAssistantBgColor || '')}&darkUserBgColor=${encodeURIComponent(darkUserBgColor || '')}&darkTextfieldBgColor=${encodeURIComponent(darkTextfieldBgColor || '')}&textfieldBgColor=${encodeURIComponent(textfieldBgColor || '')}&darkTextfieldColor=${encodeURIComponent(darkTextfieldColor || '')}&textfieldColor=${encodeURIComponent(textfieldColor || '')}`;

    this.shadowRoot.innerHTML = `
      <style>
        :host {
          position: ${ isMobile() ? 'fixed' : 'absolute' };
          display: block;
          width: ${ isMobile() ? '100%' : width};
          height: ${ isMobile() ? '100%' : height};
          left: ${ isMobile() ? '0' : left};
          top: ${ isMobile() ? '0' : top};
        }
        iframe {
          border: none;
          width: 100%;
          height: 100%;
          overflow: hidden;
        }
      </style>
      <iframe allowfullscreen
              webkitallowfullscreen
              mozallowfullscreen
              allow="microphone"
              class="iframe-app"
              height="${height}"
              width="${width}"
              src="${newUrl}">
      </iframe>
    `;
  }
}

customElements.define('asisgenio-chat', ChatComponent);
