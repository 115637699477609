class GalleryItem extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }

  connectedCallback() {
    this.render();

    const imageUrl = this.getAttribute('data-url');
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const photoLink = this.shadowRoot.querySelector('.photo-link');
      photoLink.style.opacity = '1'; // Set opacity to 1 when image is loaded
      photoLink.classList.add('loaded'); // Optionally add a loaded class for any other potential styles
    }
  }

  render() {
    const imageUrl = this.getAttribute('data-url');
    this.shadowRoot.innerHTML = `
     <style>
      :host {
        display: inline-block;
        width: 100%;
        height: 220px;
      }

      .photo-link {
        display: block;
        position: relative;
        background: center/cover no-repeat url('${imageUrl}');
        height: 100%; /* Ensure link takes full height */
        width: 100%;
        border-radius: 8px; /* Rounded corners for a softer look */
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow */
        overflow: hidden; /* Ensure rounded corners cut off overflow */
        transition: box-shadow 0.3s ease, transform 0.3s ease, opacity 1.8s ease; 
        opacity: 0; /* Start with opacity 0 for the loading effect */
      }

      .bg-cover-opacity::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.35);
        border-radius: inherit;
        transition: background 0.3s ease;
      }

      .photo-link:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* More pronounced shadow on hover */
        transform: scale(1.02); /* Slight zoom effect on hover */
      }

      .photo-link:hover .bg-cover-opacity::before {
        background: rgba(0, 0, 0, 0.2);
      }
      </style>
      <a href="#" class="photo-link">
        <div class="bg-cover-opacity"></div>
      </a>
    `;
  }
}

customElements.define('gallery-item', GalleryItem);
