export function isMobile() {
  let check = false;
  (function(a) {
    if (/(android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini)/i.test(a)) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

